// Global
import { ComponentRendering, Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { ReactElement, useEffect } from 'react';

// Lib
import { CardComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { deepSearch } from 'lib/utils/deep-search';
import { getPublicUrl } from 'lib/utils/public-url-utils';

// Local
import { CategoryInfo } from 'components/ProductInformation/ProductInformation';
import { useRealPathName } from 'lib/utils/use-real-pathname';

export type ProductInfo =
  | (ProductAndArticleComponent.ProductInformation.ProductInformation & Item)
  | undefined;
export type ProductReviewVariantInformation = ItemEx &
  ProductAndArticleComponent.ProductInformation.VariantInformation;

export type CategoryRatingScript = {
  ENABLE_CLIENT_SIDE_STRUCTURED_DATA?: boolean;
  api_key?: string;
  locale?: string;
  merchant_group_id?: string;
  merchant_id?: string;
  page_id?: string;
  components: {
    CategorySnippet: string;
  };
};

export type VariantScript = {
  name?: string;
  image_url?: string;
  upc?: string;
  page_id_variant?: string;
};

export type ReviewScript = {
  ENABLE_CLIENT_SIDE_STRUCTURED_DATA?: boolean;
  api_key?: string;
  locale?: string;
  merchant_group_id?: string;
  merchant_id?: string;
  page_id?: string;
  review_wrapper_url?: string;
  product?: {
    name?: string;
    url?: string;
    image_url?: string;
    description?: string;
    category_name?: string;
    brand_name?: string;
    price?: string;
    in_stock?: string;
    variants: VariantScript[];
  };
  subject: {
    '@context': 'https://schema.org';
    '@type': 'Product';
    name?: string;
    description?: string;
    url?: string;
    image?: string;
    sku?: string;
    brand?: string;
    offers?: object;
  };
  components: {
    ReviewSnippet: string;
    ReviewDisplay: string;
  };
};

const PowerReviewsScript = (): ReactElement => {
  const pathName = useRealPathName();

  const context = useSitecoreContext();
  const enablePowerReview = context?.sitecoreContext?.powerReviews?.enablePowerReviews;

  const productDetailPage = context?.sitecoreContext?.route as ItemEx & DartPages.ProductDetailPage;
  const productInformation = productDetailPage?.fields?.productInformation;

  //Power reviews
  const language =
    context?.sitecoreContext?.powerReviews?.locale?.replace('-', '_') ||
    context.sitecoreContext.language?.replace('-', '_');
  const merchantID = context?.sitecoreContext?.powerReviews?.merchantID;
  const merchantGroupID = context?.sitecoreContext?.powerReviews?.merchantGroupID;
  const powerReviewsApiKey = context?.sitecoreContext?.powerReviews?.powerReviewsApiKey;
  const writeAReviewPage = context?.sitecoreContext?.powerReviews?.writeAReviewPage;
  const brand = context?.sitecoreContext?.site?.name;

  //////////////////////////////////////
  //   Card Component Rating Script
  //////////////////////////////////////
  const cardRatingsScript: CategoryRatingScript[] = [];
  const cardComponents = deepSearch<
    ComponentRendering & CardComponents.ProductCard.ProductCardsList
  >(context.sitecoreContext.route, (x) => x.componentName === 'ProductCard');

  cardComponents?.forEach((component) => {
    const pageIds: ProductInfo[] = (
      component.fields?.productCards as CardComponents.ProductCard.ProductCardItem[]
    )?.map((x) => x?.fields?.productInformation);
    pageIds?.forEach((id, index) => {
      const newSnippetID = id?.fields?.oldPageId?.value ? id?.fields?.oldPageId?.value : id?.id;
      cardRatingsScript.push({
        ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
        api_key: powerReviewsApiKey,
        locale: language,
        merchant_group_id: merchantGroupID,
        merchant_id: merchantID,
        page_id: newSnippetID?.toUpperCase(),
        components: {
          CategorySnippet: `category-snippet-${component?.uid}-${newSnippetID}-${index}`,
        },
      });
    });
  });

  //////////////////////////////////////
  //   Product Info Review Script
  //////////////////////////////////////
  const reviewsScript: ReviewScript[] = [];

  // check the product information item has ratings and reviews enabled.
  if (productInformation && productInformation?.fields?.ratingAndReview?.value) {
    const categoryInfo = productDetailPage?.fields?.category as CategoryInfo[];
    const categories = Array.isArray(categoryInfo)
      ? categoryInfo?.map((c) => c.fields?.categoryNameForSearchIndex?.value)?.join('|')
      : '';
    const pageID =
      productInformation?.fields?.oldPageId?.value || productInformation?.id?.toUpperCase();

    const host =
      typeof window !== 'undefined'
        ? `${window.location.protocol}//${window.location.host}`
        : getPublicUrl();
    const url = `${host}/${context?.sitecoreContext?.language?.toLocaleLowerCase()}${pathName}`;
    const image = productInformation?.fields?.primaryImage?.value?.src;
    const name = productInformation?.fields?.headline?.value;
    const description = productInformation?.fields?.description?.value;

    const variantItemScript: VariantScript[] = [];
    productInformation?.fields?.variantItems?.map((item: ProductReviewVariantInformation) => {
      variantItemScript.push({
        name: item?.fields?.name?.value,
        image_url: item?.fields?.image?.value?.src,
        upc: item?.fields?.ean?.value,
        page_id_variant: item?.id,
      });

      return variantItemScript;
    });

    let productJson = undefined;

    if (process.env.IS_PREVIEW_SITE !== 'true') {
      productJson = {
        name: name,
        url: url,
        image_url: image,
        description: `${description?.replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '')}`,
        category_name: categories,
        brand_name: brand,
        price: '',
        in_stock: '',
        variants: variantItemScript,
      };
    }

    reviewsScript.push({
      ENABLE_CLIENT_SIDE_STRUCTURED_DATA: true,
      api_key: powerReviewsApiKey,
      locale: language,
      merchant_group_id: merchantGroupID,
      merchant_id: merchantID,
      page_id: pageID,
      review_wrapper_url: `${writeAReviewPage}?pr_page_id=${pageID}`,
      product: productJson,
      subject: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: name,
        description: `${description?.replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '')}`,
        url: url,
        image: image,
        sku: pageID,
        brand: brand,
        offers: {},
      },
      components: {
        ReviewSnippet: 'pr-reviewsnippet',
        ReviewDisplay: 'pr-reviewdisplay',
      },
    });
  }

  const finalScript = [...reviewsScript, ...cardRatingsScript];

  useEffect(() => {
    // If power reviews is disabled
    if (!enablePowerReview) {
      return;
    }

    if (!finalScript.length) {
      return;
    }

    if (finalScript.length) {
      window.pwr =
        window.pwr ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function (...args: any) {
          (window.pwr.q = window.pwr.q || []).push([...args]);
        };
      setTimeout(() => {
        window.pwr('render', finalScript);
      }, 250);
    }

    return () => {
      reviewsScript.forEach((item) => {
        window.pwr('unmount', item?.components?.ReviewSnippet);
        window.pwr('unmount', item?.components?.ReviewDisplay);
      });
      cardRatingsScript.forEach((item) => {
        window.pwr('unmount', item?.components?.CategorySnippet);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context.sitecoreContext.language,
    context?.sitecoreContext?.route?.itemId,
    enablePowerReview,
  ]);

  return <></>;
};

export default PowerReviewsScript;
